import { useState } from "react"
import Button from "../../ui/button"
import Checkbox from "../../ui/checkbox"
import Column from "../../ui/column"
import Row from "../../ui/row"
import Text from "../../ui/text"
import { useDisableWebhook, useInvalidateWebhook, useWebhookConfig } from "../../../hooks/webhook"
import Skeleton from "react-loading-skeleton"
import AlertBox from "../../ui/alert-box"
import { getErrorMessage } from "../../../utils"
import Modal from "../../ui/modal"

const DisableWebhookTab = () => {
  const {
    data: currentWebhookConfig,
    isLoading: currentWebhookIsLoading,
    isError: currentWebhookIsError,
    error: currentWebhookError
  } = useWebhookConfig()

  const {
    mutateAsync: disableWebhook,
    isLoading: disableWebhookIsLoading,
    error: disableWebhookError,
    reset: dismissDisableWebhookError
  } = useDisableWebhook()
  const invalidateWebhook = useInvalidateWebhook()

  const [ acknowledged, setAcknowledged ] = useState(false)

  const onDisableWebhookClicked = async () => {
    try {
      await disableWebhook()
      invalidateWebhook()
    } catch (e) {
      console.log(e)
    }
  }

  if (currentWebhookIsError) {
    return (
      <AlertBox title="Erro" type="error">
        {getErrorMessage(currentWebhookError)}
      </AlertBox>
    )
  }

  if (!currentWebhookConfig && !currentWebhookConfig) {
    return (
      <Text>
        Você não possui nenhum webhook ativo!<br />
      </Text>
    )
  }

  return (
    <Column gap="20px">
      <Modal open={disableWebhookError ? true : false} onClose={dismissDisableWebhookError}>
        {getErrorMessage(disableWebhookError)}
      </Modal>
      <div style={{ maxWidth: "900px" }}>
        {
          !currentWebhookIsLoading ? (
            <Text>
              <strong>Atenção:</strong><br />
              <br />
              A plataforma retém notificações de webhook por no máximo <strong>3 dias</strong>.<br />
              <br />
              Isso significa que se o webhook por desabilitado por um período de tempo superior a este, atualizações
              do webhook serão perdidas e não serão reenviadas mesmo se o webhook for cadastrado novamente.<br />
              <br />
              Recomendamos que esta funcionalidade seja usada por curtos períodos de manutenção, para evitar que seu endpoint
              de webhook receba requisições enquanto estiver fora do ar.<br />
            </Text>
          ) : (
            <Text>
              <Skeleton width={100} />
              <br />
              <Skeleton width={550} />
              <br />
              <Skeleton width={900} height={60} />
              <br />
              <Skeleton width={900} height={60} />
            </Text>
          )
        }
      </div>
      {
        currentWebhookIsLoading ? (
          <Skeleton width={200} />
        ) : (
          <Checkbox label="Li e entendi as consequências" checked={acknowledged} onCheck={setAcknowledged} />
        )
      }
      <Row>
        <Button
          layout="danger"
          disabled={!acknowledged}
          skeleton={currentWebhookIsLoading}
          loading={disableWebhookIsLoading}
          onClick={onDisableWebhookClicked}>
          Desabilitar Webhook
        </Button>
      </Row>
    </Column>
  )
}

export default DisableWebhookTab