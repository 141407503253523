import styled from "styled-components"
import Row from "../../ui/row"
import TextField from "../../ui/text-field"
import Button from "../../ui/button"
import { useCreateApiToken, useInvalidateApiTokens } from "../../../hooks/api-tokens"
import { useState } from "react"
import Modal from "../../ui/modal"
import { getErrorMessage } from "../../../utils"
import Code from "../../ui/code"
import Column from "../../ui/column"

const TokenLabelInputWrapper = styled.div`
  width: 400px;
`

const ButtonWrapper = styled.div`
  align-self: flex-end;
`

const CreateTokenForm = () => {
  const {
    mutateAsync: createApiToken,
    isLoading,
    isError,
    error,
    reset
  } = useCreateApiToken()
  const invalidateApiTokens = useInvalidateApiTokens()

  const [ label, setLabel ] = useState<string | null>(null)
  const [ tokenCreated, setTokenCreated ] = useState<{ apiToken: string, label: string } | null>(null)

  const onCreateTokenClick = async () => {
    try {
      const result = await createApiToken({ label })
      setTokenCreated(result)
      invalidateApiTokens()
    } catch (e) {
      console.log(e)
    }
  }

  const onLabelChange = (value: string | null) => {
    if (!value) {
      setLabel(value)
      return
    }
    setLabel(value.toUpperCase().replace(/[^0-9A-Z]/g, "_"))
  }

  return (
    <Row gap="15px">
      <Modal open={tokenCreated !== null} type="BLANK">
        <div style={{ width: "100%" }}>
          <Column align="left" gap="20px">
            <p>
              O token <strong>{tokenCreated?.label}</strong> foi criado com sucesso!
            </p>
            <p>
              <strong>Atenção:</strong><br />
              <ol>
                <li>Copie o valor do token e guarde em um lugar seguro.</li>
                <li>Não faça requisições usando o token através do Front End da sua aplicação</li>
                <li>Copie agora o conteúdo do token, por razões de segurança, ele não será exibido novamente.</li>
              </ol>
            </p>
            <Code code={tokenCreated?.apiToken || ""} />
            <Row align="center">
              <Button onClick={() => setTokenCreated(null)}>
                Já copiei o Token
              </Button>
            </Row>
          </Column>
        </div>
      </Modal>
      <Modal open={isError} onClose={reset}>
        {getErrorMessage(error)}
      </Modal>
      <TokenLabelInputWrapper>
        <TextField
          label="Identificador do Token"
          placeholder="TOKEN_NAME"
          value={label}
          onChange={onLabelChange}
        />
      </TokenLabelInputWrapper>
      <ButtonWrapper>
        <Button onClick={onCreateTokenClick} loading={isLoading}>
          Criar Token
        </Button>
      </ButtonWrapper>
    </Row>
  )
}

export default CreateTokenForm