import { BrowserRouter, Routes as ReactRouterRoutes, Route } from 'react-router-dom'

import HomePage from './components/pages/home/home-page'
import LoginPage from './components/pages/login/login-page'
import AppContextProvider from './context/app-context-provider'
import { QueryClient, QueryClientProvider } from 'react-query'
import TokensPage from './components/pages/tokens/tokens-page'
import WebhookPage from './components/pages/webhook/webhook-page'
import DocumentationPage from './components/pages/documentation/documentation-page'
import ReceiptsPage from './components/pages/receipts/receipts-page'
import ContactPage from './components/pages/contact/contact-page'

const queryClient = new QueryClient()

const Routes = (
  <>
    <Route path="/" element={<HomePage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/docs" element={<DocumentationPage />} />
    <Route path="/receipts" element={<ReceiptsPage />} />
    <Route path="/api-tokens" element={<TokensPage />} />
    <Route path="/webhook" element={<WebhookPage />} />
    <Route path="/contact" element={<ContactPage />} />
  </>
)

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContextProvider>
          <ReactRouterRoutes>
            {Routes}
          </ReactRouterRoutes>
        </AppContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
