import styled from "styled-components"
import Gap from "../../ui/gap"
import { ReactNode } from "react"
import AccountOptions from "./account-options"
import ProjectSelector from "./project-selector"
import Menu from "./menu"

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const LeftBar = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  background-color: #1F2BA0;
  width: 250px;
  color: white;
`

const Logo = styled.div`
  font-size: 20px;
  padding: 15px;
  font-weight: bolder;
`

const Spacer = styled.div`
  height: 15px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TopNav = styled.div`
  border-bottom: solid 1px gray;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 5px;
`

type Props = {
  children?: ReactNode
}

const Layout = (props: Props) => {
  return (
    <Wrapper>
      <LeftBar>
        <Logo>
          Argo
        </Logo>
        <ProjectSelector />
        <Spacer />
        <Menu />
      </LeftBar>
      <Content>
        <TopNav>
          <Gap />
          <AccountOptions />
        </TopNav>
        {props.children}
      </Content>
    </Wrapper>
  )
}

export default Layout