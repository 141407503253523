import { ReactNode } from "react"
import styled from "styled-components";

type Props = {
  children?: ReactNode;
  type?: "info" | "error";
  title?: string
}

const Wrapper = styled.div<{ type: "info" | "error" }>`
  background-color: ${props => {
    if (props.type === "info") {
      return "#cce5ff"
    }
    if (props.type === "error") {
      return "#f8d7da"
    }
  }};
  border: solid 1px ${props => {
    if (props.type === "info") {
      return "#004085"
    }
    if (props.type === "error") {
      return "#721c24"
    }
  }};
  color: ${props => {
    if (props.type === "info") {
      return "#004085"
    }
    if (props.type === "error") {
      return "#721c24"
    }
  }};
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-weight: bolder;
`

const AlertBox = (props: Props) => {
  const {
    children, title, type = "info"
  } = props
  return (
    <Wrapper type={type}>
      {title ? <Title>{title}</Title> : null}
      {children}
    </Wrapper>
  )
}

export default AlertBox