import styled, { css } from "styled-components"

type Props = {
  checked?: boolean,
  onCheck?: (value: boolean) => void,
  label?: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
  cursor: pointer;
`

const Box = styled.div<{ checked: boolean }>`
  height: 20px;
  width: 20px;
  padding: 3px;
  box-sizing: border-box;
  border: solid 1px gray;
  align-items: center;
  border-radius: 3px;
  position: relative;
  top: -1px;
  ${props => {
    return props.checked && css`
      &::after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        background-color: black;
      }
    `
  }}
`

const Checkbox = (props: Props) => {
  const {
    onCheck, label, checked = false
  } = props
  return (
    <Wrapper onClick={() => onCheck?.(!checked)}>
      <Box checked={checked} />
      {label}
    </Wrapper>
  )
}

export default Checkbox