import { ReactNode } from "react"
import styled from "styled-components"

type Props = {
  page?: number;
  onChange?: (page: number) => void;
  total?: number;
}

const Page = styled.div<{ active: boolean }>`
  background-color: ${props => {
    if (props.active) {
      return "#0F1B8D"
    }
    return "#4550B7"
  }};
  color: white;
  font-size: 14px;
  width: 25px;
  line-height: 25px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #0F1B8D !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  &:hover ${Page} {
    background-color: #4550B7;
  }
`

const Pagination = (props: Props) => {
  const {
    page, onChange, total = 1
  } = props

  if (total <= 1) {
    return null
  }

  const pages: ReactNode[] = []

  for (let i = 1; i <= total; i++) {
    pages.push(
      <Page active={i === page} onClick={() => onChange?.(i)}>
        {i}
      </Page>
    )
  }

  return (
    <Wrapper>
      {pages}
    </Wrapper>
  )
}

export default Pagination