import Column from "../../ui/column"
import SefazStatusBar from "./sefaz-status-bar"
import { getUfByCode, UFs } from "../../../utils/uf"
import styled from "styled-components"

const BarsWrapper = styled.div`
  max-width: 1000px;
`

const StatusSefazTab = () => {
  return (
    <Column gap="10px">
      <BarsWrapper>
        <Column gap="15px" padding="5px 0 100px 0">
          {
            UFs.map(uf => (
              <SefazStatusBar
                reports={[]}
                startDt={date90daysAgo()}
                endDt={new Date()}
                key={uf.code}
                uf={getUfByCode(uf.code)} />
            ))
          }
        </Column>
      </BarsWrapper>
    </Column>
  )
}

function date90daysAgo() {
  const dataAtual = new Date()
  dataAtual.setDate(dataAtual.getDate() - 90)
  return dataAtual
}

export default StatusSefazTab