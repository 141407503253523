import { Navigate } from "react-router"
import { getAuthToken } from "../utils"

const withRedirectUnauthenticated = <T extends Object>(
  Component: React.ComponentType<T>
) => {
  return (props: T) => {
    const authToken = getAuthToken()

    if (!authToken) {
      return (
        <Navigate to="/login" replace />
      )
    }
  
    return (
      <Component {...props} />
    )
  }
}

export default withRedirectUnauthenticated