
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import Column from "../../ui/column"
import Table from "../../ui/table"
import Text from "../../ui/text"
import Title from "../../ui/title"
import Layout from "../layout/layout"

const ContactPage = () => {
  return (
    <Layout>
      <Column padding="15px" gap="20px">
        <Column>
          <Title>
            Contato
          </Title>
          <Text>
            Canais para entrar em contato caso precise de ajuda
          </Text>
        </Column>
        <Table mainColumn={1}>
          <thead>
            <th>
              Assunto
            </th>
            <th>
              E-Mail
            </th>
          </thead>
          <tbody>
            <tr>
              <td>
                Reportar Bugs, dúvidas técnicas
              </td>
              <td>
                api@argobr.com
              </td>
            </tr>
            <tr>
              <td>
                Demais problemas
              </td>
              <td>
                suporte@argobr.com
              </td>
            </tr>
          </tbody>
        </Table>
      </Column>
    </Layout>
  )
}

export default withRedirectUnauthenticated(ContactPage)