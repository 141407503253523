import styled from "styled-components"
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import Button from "../../ui/button"
import Column from "../../ui/column"
import Row from "../../ui/row"
import SelectField from "../../ui/select-field"
import Text from "../../ui/text"
import TextField from "../../ui/text-field"
import Title from "../../ui/title"
import Layout from "../layout/layout"
import Gap from "../../ui/gap"
import { FetchReceiptQueueParams, usePeriodFilterOptions, useReceiptQueue } from "../../../hooks/receipts"
import { useEffect, useMemo, useState } from "react"
import Table from "../../ui/table"
import Skeleton from "react-loading-skeleton"
import ReceiptStatusBullet from "./receipt-status-bullet"
import Pagination from "../../ui/pagination"

const ufOptions = [
  { value: null, description: "Todas" },
  { value: "AC", description: "Acre" },
  { value: "AL", description: "Alagoas" },
  { value: "AP", description: "Amapá" },
  { value: "AM", description: "Amazonas" },
  { value: "BA", description: "Bahia" },
  { value: "CE", description: "Ceará" },
  { value: "DF", description: "Distrito Federal" },
  { value: "ES", description: "Espírito Santo" },
  { value: "GO", description: "Goiás" },
  { value: "MA", description: "Maranhão" },
  { value: "MT", description: "Mato Grosso" },
  { value: "MS", description: "Mato Grosso do Sul" },
  { value: "MG", description: "Minas Gerais" },
  { value: "PA", description: "Pará" },
  { value: "PB", description: "Paraíba" },
  { value: "PR", description: "Paraná" },
  { value: "PE", description: "Pernambuco" },
  { value: "PI", description: "Piauí" },
  { value: "RJ", description: "Rio de Janeiro" },
  { value: "RN", description: "Rio Grande do Norte" },
  { value: "RS", description: "Rio Grande do Sul" },
  { value: "RO", description: "Rondônia" },
  { value: "RR", description: "Roraima" },
  { value: "SC", description: "Santa Catarina" },
  { value: "SP", description: "São Paulo" },
  { value: "SE", description: "Sergipe" },
  { value: "TO", description: "Tocantins" }
];

const ButtonWrapper = styled.div`
  align-self: flex-end;
`

const PeriodSelectWrapper = styled.div`
  width: 100%;
  max-width: 200px;
`

const UFSelectWrapper = styled.div`
  width: 100%;
  max-width: 200px;
`

const AccessKeySelectWrapper = styled.div`
  width: 100%;
  max-width: 400px;
`

const ReceiptsPage = () => {
  const [ page, setPage ] = useState(1)
  const [ period, setPeriod ] = useState<string | null>(null)
  const [ uf, setUf ] = useState<string | null>(null)
  const [ accessKey, setAccessKey ] = useState<string | null>(null)

  const {
    data: filterOptions,
    isLoading: filterOptionsIsLoading
  } = usePeriodFilterOptions()

  const {
    data: receiptQueue,
    isLoading: receiptQueueIsLoading,
    fetch: fetchReceiptQueue
  } = useReceiptQueue()

  const filterOptionsAdjusted = useMemo(() => {
    if (!filterOptions) {
      return undefined
    }
    return [
      {
        value: null, description: "Todos"
      },
      ...filterOptions.map(option => ({ value: `${option.start}$${option.end}`, description: formatDateToMonthYear(option.start) }))
    ]
  }, [ filterOptions ])

  const getFetchParams = (page: number) => {
    const params: FetchReceiptQueueParams = { page }

    if (accessKey) {
      params.accessKeys =  [ accessKey ]
    }
    if (uf) {
      params.uf = uf
    }
    if (period) {
      const parts = period.split("$")
      params.period = parts
    }

    return params
  }

  const onChangePage = (page: number) => {
    setPage(page)
    fetchReceiptQueue(getFetchParams(page))
  }

  useEffect(() => {
    fetchReceiptQueue({})
  }, [ fetchReceiptQueue ])

  return (
    <Layout>
      <Column padding="15px">
        <Title>
          Notas
        </Title>
        <Text>
          Consulte o status de suas chaves de acesso
        </Text>
        <Row gap="15px" padding="15px 0" >
          <PeriodSelectWrapper>
            <SelectField
              label="Período"
              options={filterOptionsAdjusted}
              skeleton={filterOptionsIsLoading}
              value={period}
              onChange={setPeriod}
            />
          </PeriodSelectWrapper>
          <UFSelectWrapper>
            <SelectField
              label="UF"
              options={ufOptions}
              skeleton={filterOptionsIsLoading}
              value={uf}
              onChange={setUf}
            />
          </UFSelectWrapper>
          <AccessKeySelectWrapper>
            <TextField
              label="Chave de Acesso"
              skeleton={filterOptionsIsLoading}
              placeholder="Chave de acesso de 44 dígitos"
              value={accessKey}
              onChange={setAccessKey}
            />
          </AccessKeySelectWrapper>
          <ButtonWrapper>
            <Button
              skeleton={filterOptionsIsLoading}
              width={170}
              onClick={() => onChangePage(1)}
              loading={receiptQueueIsLoading}>
              Filtrar
            </Button>
          </ButtonWrapper>
          <Gap />
          <ButtonWrapper style={{ display: "none" }}>
            <Button layout="primary" skeleton={filterOptionsIsLoading} width={175}>
              Enviar QR Code
            </Button>
          </ButtonWrapper>
        </Row>
        {
          receiptQueueIsLoading ? (
            <Skeleton height={600} />
          ) : (
            <>
              <Table mainColumn={1}>
                <thead>
                  <tr>
                    <th>Chave de Acesso</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    receiptQueue && receiptQueue.accessKeys.map(item => (
                      <tr key={item.accessKey}>
                        <td style={{ fontFamily: "monospace" }}>
                          {item.accessKey}
                        </td>
                        <td>
                          <ReceiptStatusBullet status={item.status}  />
                        </td>
                      </tr>
                    ))
                  }
                  {
                    receiptQueue && !receiptQueue.accessKeys.length && (
                      <tr>
                        <td colSpan={2}>
                          Nenhuma nota foi encontrada
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </Table>
              <Pagination
                total={receiptQueue?.pageTotal}
                page={page}
                onChange={onChangePage}
              />
            </>
          )
        }
      </Column>
    </Layout>
  )
}

export default withRedirectUnauthenticated(ReceiptsPage)

function formatDateToMonthYear(dateString: string) {
  const date = new Date(dateString)
  const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ]

  const year = date.getFullYear()
  const month = months[date.getMonth() + 1]

  return `${month} de ${year}`
}