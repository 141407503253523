
import Column from "../../ui/column"
import Title from "../../ui/title"
import Text from "../../ui/text"
import Layout from "../layout/layout"
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import { useApiTokens } from "../../../hooks/api-tokens"
import Table from "../../ui/table"
import { formatSecondsTimestamp } from "../../../utils"
import TokenOptionsButton from "./token-options-button"
import CreateTokenForm from "./create-token-form"
import styled from "styled-components"
import Skeleton from "react-loading-skeleton"

const SkeletonWrapper = styled.div`
  line-height: 1;
`

const TokensPage = () => {
  const { data: apiTokens, isLoading: isApiTokensLoading } = useApiTokens()
 
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Column>
          <Title>
            Tokens
          </Title>
          <Text>
            Tokens permitem autenticação para utilização da API
          </Text>
        </Column>
        <CreateTokenForm />
        {
          !isApiTokensLoading ? null : (
            <SkeletonWrapper>
              <Skeleton height={300} />
            </SkeletonWrapper>
          )
        }
        {
          !apiTokens ? null : (
            <Table mainColumn={1}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Criação</th>
                  <th>Expiração</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  apiTokens.map(apiToken => (
                    <tr>
                      <td>{apiToken.label}</td>
                      <td>{formatSecondsTimestamp(apiToken.createdAt)}</td>
                      <td>{formatSecondsTimestamp(apiToken.expiresAt)}</td>
                      <td>
                        <TokenOptionsButton token={apiToken} />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          )
        }
      </Column>
    </Layout>
  )
} 

export default withRedirectUnauthenticated(TokensPage)