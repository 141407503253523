import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

function useUrlState<T extends string | undefined>(paramName: string, defaultValue?: T): [ 
  T extends undefined ? string | undefined : string, (value: string) => void
] {
  const location = useLocation()
  const navigate = useNavigate()

  const setValue = useCallback((newValue: string) => {
    const newParams = new URLSearchParams(window.location.search)
    newParams.set(paramName, newValue)
    navigate(`${window.location.pathname}?${newParams}`)
  }, [ navigate, paramName ])

  const searchParams = new URLSearchParams(location.search);
  const value = searchParams.get(paramName) ?? defaultValue;

  return [ value as T extends undefined ? string | undefined : string, setValue ]
}

export default useUrlState
