import { FormEvent, useEffect, useState } from "react"
import Button from "../../ui/button"
import Row from "../../ui/row"
import TextField from "../../ui/text-field"
import styled from "styled-components"
import useRequest from "../../../hooks/use-request"
import useAppContext from "../../../context/use-app-context"
import { getAuthToken, setAuthToken } from "../../../utils"
import { useNavigate } from "react-router"

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const LoginTab = () => {
  const { onErrorAlert, onProjectSelected } = useAppContext()
  const request = useRequest()
  const navigate = useNavigate()

  const [ username, setUsername ] = useState<string | null>(null)
  const [ password, setPassword ] = useState<string | null>("")
  const [ loading, setLoading ] = useState(false)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    try {
      const result = await request<{ token: string, defaultProjectId: number }>({
        method: "POST",
        url: "/v1/auth/session",
        publicRequest: true,
        body: {
          username, password
        }
      })
      setAuthToken(result.body.token)
      onProjectSelected(result.body.defaultProjectId)
      navigate("/")
    } catch (e) {
      onErrorAlert(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (getAuthToken()) {
      navigate("/")
    }
  }, [ navigate ])

  return (
    <Wrapper onSubmit={onSubmit}>
      <TextField
        invertColors
        label="Nome de Usuário ou E-mail"
        placeholder="Nome de Usuário ou E-mail"
        value={username}
        onChange={setUsername}
      />
      <TextField
        invertColors
        label="Senha"
        placeholder="Senha"
        type="password"
        value={password}
        onChange={setPassword}
      />
      <Row align="center" padding="10px">
        <Button loading={loading} disabled={!username || !password} type="submit" width={160}>
          Acessar
        </Button>
      </Row>
    </Wrapper>
  )
}

export default LoginTab