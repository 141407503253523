import Column from "../../ui/column"
import Title from "../../ui/title"
import Text from "../../ui/text"
import Layout from "../layout/layout"
import Tabs from "../../ui/tabs"
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import useUrlState from "../../../hooks/use-url-state"
import ConfigWebhookTab from "./config-webhook-tab"
import DisableWebhookTab from "./disable-webhook-tab"

const WebhookPage = () => {
  const [ tab, setTab ] = useUrlState("tab", "config")
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Column>
          <Title>
            Webhook
          </Title>
          <Text>
            O webhook é um endpoint em uma API disponibilizada por você que iremos chamar para te notificar de eventos da plataforma.
          </Text>
        </Column>
        <Tabs 
          value={tab}
          onChange={setTab}
          options={[
            { id: "config", name: "Configuração" },
            { id: "disable", name: "Desativar Webhook" }
          ]}
        />
        {tab === "config" ? <ConfigWebhookTab /> : null}
        {tab === "disable" ? <DisableWebhookTab /> : null}
      </Column>
    </Layout>
  )
} 

export default withRedirectUnauthenticated(WebhookPage)