import { useCallback } from "react"
import { ApiToken } from "../types"
import useRequest from "./use-request"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useAppContext from "../context/use-app-context"

export const API_TOKENS_KEY = "API_TOKENS"

export const useApiTokens = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const fetchApiTokens = useCallback(() => request<{ result: ApiToken[] }>({
    url: "/v1/auth/api-token?projectId=" + projectId
  }).then(response => response.body.result), [ request, projectId ])

  return useQuery([ API_TOKENS_KEY, projectId ], fetchApiTokens)
}

export const useInvalidateApiTokens = () => {
  const queryClient = useQueryClient()
  const { projectId } = useAppContext()

  return useCallback(() => {
    queryClient.invalidateQueries([ API_TOKENS_KEY, projectId ], {
      refetchActive: true
    })
  }, [ queryClient, projectId ])
}

export const useCreateApiToken = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const createApiToken = useCallback((params: { label?: string | null }) => request<{ apiToken: string, label: string }>({
    method: "POST",
    url: "/v1/auth/api-token",
    body: {
      label: params.label, projectId
    }
  }).then(response => response.body), [ request, projectId ])

  return useMutation(createApiToken)
}

export const useDeleteApiToken = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const deleteApiToken = useCallback((params: { label: string }) => request({
    method: "DELETE",
    url: "/v1/auth/api-token/" + params.label + "?projectId=" + projectId,
  }).then(response => response.body), [ request, projectId ])

  return useMutation(deleteApiToken)
}

export const useRenameApiToken = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const renameApiToken = useCallback((params: { label: string, newLabel: string }) => request({
    method: "PUT",
    url: "/v1/auth/api-token/" + params.label + "/label/" + params.newLabel + "?projectId=" + projectId,
  }).then(response => response.body), [ request, projectId ])

  return useMutation(renameApiToken)
}