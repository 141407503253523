import { ReactNode } from "react"
import styled from "styled-components"
import LoadingAnimatedIcon from "./loading-animated-icon";
import Skeleton from "react-loading-skeleton";

type Props = {
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  type?: "button" | "submit";
  layout?: "primary" | "secundary" | "danger"
  onClick?: (e: React.MouseEvent) => void;
  skeleton?: boolean;
  skeletonWidth?: number;
  success?: boolean;
  width?: number;
}

const Wrapper = styled.button<{ layout: "primary" | "secundary" | "danger", success: boolean, width?: number }>`
  user-select: none;
  ${props => {
    if (props.width) {
      return `width: ${props.width}px`;
    }
  }};
  background-color: ${props => {
    if (props.layout === "primary") {
      return "#5FB95F"
    }
    if (props.layout === "secundary") {
      return "transparent"
    }
    if (props.layout === "danger") {
      return "red"
    }
  }};
  border: solid 1px ${props => {
    if (props.layout === "primary") {
      return "#008000"
    }
    if (props.layout === "secundary") {
      return "black"
    }
    if (props.layout === "danger") {
      return "darkred"
    }
  }};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => {
    if (props.layout === "primary") {
      return "white"
    }
    if (props.layout === "secundary") {
      return "black"
    }
    if (props.layout === "danger") {
      return "white"
    }
  }};
  font-weight: bolder;
  padding: 0 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  ${props => props.success && "pointer-events: none;"}
  &:hover {
    background-color: ${props => {
      if (props.layout === "primary") {
        return "#208b20"
      }
      if (props.layout === "secundary") {
        return "transparent"
      }
      if (props.layout === "danger") {
        return "darkred"
      }
    }};
  }
  &:disabled {
    background-color: ${props => {
      if (props.layout === "primary") {
        return "#5FB95F"
      }
      if (props.layout === "secundary") {
        return "transparent"
      }
      if (props.layout === "danger") {
        return "red"
      }
    }} !important;
    opacity: 0.5;
    cursor: auto;
  }
`

const SkeletonWrapper = styled.div`
  line-height: 1;
  margin-top: 2px;
`

const Button = (props: Props) => {
  const {
    children, type = "button", onClick, loading = false,
    disabled = false, layout = "primary", skeleton = false, width,
    success = false
  } = props

  if (skeleton) {
    return (
      <SkeletonWrapper>
        <Skeleton height={40} width={width} />
      </SkeletonWrapper>
    )
  }

  return (
    <>
      <Wrapper width={width} type={type} onClick={onClick} disabled={disabled || loading} layout={layout} success={success}>
        {
          loading ? (
            <>
              <LoadingAnimatedIcon /> Carregando...
            </>
          ) : (
            success ? (
              "Salvo!"
            ) : (
              children
            )
          )
        }
      </Wrapper>
    </>
  )
}

export default Button