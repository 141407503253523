import styled from "styled-components"
import TextField from "../../ui/text-field"
import Button from "../../ui/button"
import Row from "../../ui/row"

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const RecoverPasswordTab = () => {
  return (
    <Wrapper>
      <TextField
        invertColors
        label="Nome de Usuário ou E-mail"
        placeholder="Nome de Usuário ou E-mail"
      />
      <Row align="center" padding="10px">
        <Button type="submit" width={220}>
          Recuperar Senha
        </Button>
      </Row>
    </Wrapper>
  )
}

export default RecoverPasswordTab