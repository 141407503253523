import { useEffect, useState } from "react"
import { useInvalidateWebhook, useSaveWebhookConfig, useWebhookConfig } from "../../../hooks/webhook"
import Button from "../../ui/button"
import Column from "../../ui/column"
import Row from "../../ui/row"
import TextField from "../../ui/text-field"
import { getErrorMessage, isUrlValid } from "../../../utils"
import Modal from "../../ui/modal"

type Validation = {
  url?: string;
  token?: string;
  valid: boolean;
}

const ConfigWebhookTab = () => {
  const {
    data: currentWebhookConfig,
    isLoading: currentWebhookIsLoading,
    isError: currentWebhookIsError
  } = useWebhookConfig()
  const {
    mutateAsync: saveWebhook,
    isLoading: saveWebhookIsLoading
  } = useSaveWebhookConfig()
  const invalidateWebhook = useInvalidateWebhook()

  const [ url, setUrl ] = useState<string | null>(null)
  const [ token, setToken ] = useState<string | null>(null)
  const [ validation, setValidation ] = useState<Validation>({ valid: false })
  const [ error, setError ] = useState<any>(null)
  const [ success, setSuccess ] = useState<boolean>(false)

  useEffect(() => {
    const validation: Validation = { valid: true }

    if (!url) {
      validation.url = "A url é obrigatória"
      validation.valid = false
    } else if (!url.match(/^https?:\/\//) || !isUrlValid(url)) {
      validation.url = "A url é inválida"
      validation.valid = false
    }

    if (token && token.match(/\s/)) {
      validation.token = "O token não pode conter espaços"
      validation.valid = false
    }

    setValidation(validation)

  }, [ setValidation, url, token ])

  useEffect(() => {
    if (!currentWebhookConfig) return

    setUrl(currentWebhookConfig.url)
    setToken(currentWebhookConfig.authToken || null)
  }, [ currentWebhookConfig ])

  useEffect(() => {
    if (!success) {
      return
    }

    const handle = setTimeout(() => {
      setSuccess(false)
    }, 3000)

    return () => clearTimeout(handle)
  }, [ success, setSuccess ])

  const onSaveWebhookClick = async () => {
    try {
      await saveWebhook({ url, authToken: token })
      invalidateWebhook()
      setSuccess(true)
    } catch (e) {
      setError(e)
    }
  }

  if (currentWebhookIsError) {
    return (
      <div>
        Erro
      </div>
    )
  }

  return (
    <div style={{ maxWidth: "600px" }}>
      <Modal
        open={error !== null}
        onClose={() => setError(null)}>
        {getErrorMessage(error)}
      </Modal>
      <Column gap="20px">
        <TextField
          label="URL*"
          placeholder="https://suaapi.com.br"
          onChange={setUrl}
          value={url}
          skeleton={currentWebhookIsLoading}
          validationMessage={validation.url}
        />
        <TextField
          label="Token de Segurança"
          placeholder="Token"
          description={<>Uma string que mandaremos no header <strong>Authorization: Bearer</strong> para autenticação da requisição.</>}
          onChange={setToken}
          value={token}
          skeleton={currentWebhookIsLoading}
          validationMessage={validation.token}
        />
        <Row>
          <Button
            skeleton={currentWebhookIsLoading}
            skeletonWidth={175.14}
            disabled={!validation.valid}
            loading={saveWebhookIsLoading}
            success={success}
            onClick={onSaveWebhookClick}>
            Salvar Webhook
          </Button>
        </Row>
      </Column>
    </div>
  )
}

export default ConfigWebhookTab