import { useCallback } from "react"
import useAppContext from "../context/use-app-context"
import { getAuthToken } from "../utils"

type RequestFunction = <T>(request: Request) => Promise<Response<T>>

type Request = {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: any;
  url: string;
  headers?: Record<string, string>;
  publicRequest?: boolean
}

type Response<T> = {
  status: number;
  body: T
}

export class RejectedHttpResponseError extends Error {
  constructor(
    public readonly status: number,
    public readonly body: any
  ) {
    super(body?.message || "Um erro ocorreu")
  }
}

const useRequest = () => {
  const { onAuthError } = useAppContext()

  const request: RequestFunction = useCallback((params: Request) => {
    const {
      method = "GET",
      body,
      url,
      headers = {},
      publicRequest = false
    } = params

    headers['Content-Type'] = `application/json; charset=utf-8`

    if (!publicRequest) {
      const basicToken = getAuthToken()
      if (!basicToken) {
        onAuthError()
        return new Promise(() => {})
      }
      headers['Authorization'] = `Basic ${basicToken}`
    }

    return new Promise((resolve, reject) => {

      fetch(process.env.REACT_APP_API_URL + url, {
        method: method,
        headers: headers,
        body: method !== "GET" ? JSON.stringify(body) : undefined
      }).then(response => {

        const processResponse = (data: any) => {
          if (response.status === 401 && !publicRequest) {
            onAuthError()
          } else if (!response.ok) {
            reject(new RejectedHttpResponseError(response.status, data))
          } else {
            resolve({
              status: response.status,
              body: data
            })
          }
        }

        const contentType = response.headers.get('Content-Type')

        if (contentType && contentType.includes('application/json')) {
          return response.json()
            .then(processResponse)
            .catch(error => {
              reject(new Error('Error parsing JSON: ' + error.message))
            })
        } else {
          processResponse(null)
        }

      }).catch(error => {
        reject(new Error('Network error: ' + error.message))
      })
    }) 

  }, [ onAuthError ])

  return request
}

export default useRequest