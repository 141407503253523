import styled from "styled-components"
import Column from "../../ui/column"
import Row from "../../ui/row"
import SelectField from "../../ui/select-field"
import { usePeriodFilterOptions, useReceiptsInvalid, useReceiptsProcessed, useReceiptsSent } from "../../../hooks/receipts"
import { useMemo, useState } from "react"
import Button from "../../ui/button"
import BigNumberCard from "./big-number-card"
import Skeleton from "react-loading-skeleton"

const PeriodSelectWrapper = styled.div`
  width: 400px;
`

const UFsSelectWrapper = styled.div`
  width: 200px;
`

const ButtonWrapper = styled.div`
  align-self: flex-end;
`

const CardWrapper = styled.div`
  flex: 1;
`

const SkeletonWrapper = styled.div`
  line-height: 1;
`

const ufOptions = [
  { value: null, description: "Todas" },
  { value: "AC", description: "Acre" },
  { value: "AL", description: "Alagoas" },
  { value: "AP", description: "Amapá" },
  { value: "AM", description: "Amazonas" },
  { value: "BA", description: "Bahia" },
  { value: "CE", description: "Ceará" },
  { value: "DF", description: "Distrito Federal" },
  { value: "ES", description: "Espírito Santo" },
  { value: "GO", description: "Goiás" },
  { value: "MA", description: "Maranhão" },
  { value: "MT", description: "Mato Grosso" },
  { value: "MS", description: "Mato Grosso do Sul" },
  { value: "MG", description: "Minas Gerais" },
  { value: "PA", description: "Pará" },
  { value: "PB", description: "Paraíba" },
  { value: "PR", description: "Paraná" },
  { value: "PE", description: "Pernambuco" },
  { value: "PI", description: "Piauí" },
  { value: "RJ", description: "Rio de Janeiro" },
  { value: "RN", description: "Rio Grande do Norte" },
  { value: "RS", description: "Rio Grande do Sul" },
  { value: "RO", description: "Rondônia" },
  { value: "RR", description: "Roraima" },
  { value: "SC", description: "Santa Catarina" },
  { value: "SP", description: "São Paulo" },
  { value: "SE", description: "Sergipe" },
  { value: "TO", description: "Tocantins" }
];

const DashboardTab = () => {
  const [ period, setPeriod ] = useState<string | null>(getDefaultPeriod())
  const [ uf, setUf ] = useState<string | null>(null)

  const [ selectedPeriod, setSelectedPeriod ] = useState<{ start: string, end: string } | null>(convertPeriodString(getDefaultPeriod()))
  const [ selectedUf, setSelectedUf ] = useState<string | null>(null)

  const {
    data: receiptsSentWrapper
  } = useReceiptsSent({ 
    start: selectedPeriod?.start || null,
    end: selectedPeriod?.end || null,
    uf: selectedUf
  })

  const {
    data: receiptsProcessedWrapper
  } = useReceiptsProcessed({ 
    start: selectedPeriod?.start || null,
    end: selectedPeriod?.end || null,
    uf: selectedUf
  })

  const {
    data: receiptsInvalidWrapper
  } = useReceiptsInvalid({ 
    start: selectedPeriod?.start || null,
    end: selectedPeriod?.end || null,
    uf: selectedUf
  })

  const {
    data: filterOptions,
    isLoading: filterOptionsIsLoading
  } = usePeriodFilterOptions()

  const filterOptionsAdjusted = useMemo(() => {
    if (!filterOptions) {
      return undefined
    }
    return [
      {
        value: null, description: "Todos"
      },
      ...filterOptions.map(option => ({ value: `${option.start}$${option.end}`, description: formatDateToMonthYear(option.start) }))
    ]
  }, [ filterOptions ])

  const onFilterClick = () => {
    setSelectedPeriod(convertPeriodString(period))
    setSelectedUf(uf)
  }

  return (
    <Column>
      <Row gap="15px">
        <PeriodSelectWrapper>
          <SelectField
            label="Período"
            options={filterOptionsAdjusted}
            skeleton={filterOptionsIsLoading}
            value={period}
            onChange={setPeriod}
          />
        </PeriodSelectWrapper>
        <UFsSelectWrapper>
          <SelectField
            label="UF"
            options={ufOptions}
            skeleton={filterOptionsIsLoading}
            value={uf}
            onChange={setUf}
          />
        </UFsSelectWrapper>
        <ButtonWrapper>
          <Button
            skeleton={filterOptionsIsLoading}
            width={170}
            onClick={onFilterClick}>
            Filtrar
          </Button>
        </ButtonWrapper>
      </Row>
      <Row padding="15px 0" gap="15px">
        <CardWrapper>
          {
            receiptsSentWrapper ? (
              <BigNumberCard
                title="Notas Enviadas"
                value={String(receiptsSentWrapper.receiptsSent)}
              />
            ) : (
              <SkeletonWrapper>
                <Skeleton height={85} />
              </SkeletonWrapper>
            )
          }
        </CardWrapper>
        <CardWrapper>
          {
            receiptsProcessedWrapper ? (
              <BigNumberCard
                title="Notas Processadas"
                value={String(receiptsProcessedWrapper.receiptsProcessed)}
              />
            ) : (
              <SkeletonWrapper>
                <Skeleton height={85} />
              </SkeletonWrapper>
            )
          }
        </CardWrapper>
        <CardWrapper>
          {
            receiptsInvalidWrapper ? (
              <BigNumberCard
                title="Notas Inválidas"
                value={String(receiptsInvalidWrapper.receiptsInvalid)}
              />
            ) : (
              <SkeletonWrapper>
                <Skeleton height={85} />
              </SkeletonWrapper>
            )
          }
        </CardWrapper>
      </Row>
    </Column>
  )
}

export default DashboardTab

function formatDateToMonthYear(dateString: string) {
  const date = new Date(dateString)
  const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ]

  const year = date.getFullYear()
  const month = months[date.getMonth() + 1]

  return `${month} de ${year}`
}

function getDefaultPeriod(): string {
  const hoje: Date = new Date();
  const primeiroDiaMes: Date = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
  const ultimoDiaMes: Date = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);
  
  const primeiroDiaFormatado: string = `${primeiroDiaMes.getFullYear()}-${(primeiroDiaMes.getMonth() + 1).toString().padStart(2, '0')}-01`;
  const ultimoDiaFormatado: string = `${ultimoDiaMes.getFullYear()}-${(ultimoDiaMes.getMonth() + 1).toString().padStart(2, '0')}-${ultimoDiaMes.getDate()}`;
  
  return `${primeiroDiaFormatado}$${ultimoDiaFormatado}`;
}

function convertPeriodString(period: string | null): { start: string, end: string } | null {
  if (!period) {
    return null
  }
  const parts = period.split("$")
  return {
    start: parts[0],
    end: parts[1]
  }
}