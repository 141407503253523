import { ReactNode } from "react"
import styled from "styled-components"

const Wrapper = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 28px;
`

const Title = (props: { children?: ReactNode }) => {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}

export default Title