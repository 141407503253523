import { useState } from 'react';

export default function useLocalStorage<T>(key: string): [T | null, (value: T | null) => void] {

  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : null
    } catch (error) {
      console.error('useLocalStorage: Error reading localStorage', error);
      return null;
    }
  });

  const setValue = (value: null | T | ((val: T | null) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('useLocalStorage: Error writing to localStorage', error);
    }
  };

  return [ storedValue, setValue ]
}
