import { useCallback, useState } from "react"
import useRequest from "./use-request"
import { useQuery } from "react-query"
import useAppContext from "../context/use-app-context"

export const PERIOD_FILTER_OPTIONS_KEY = "PERIOD_FILTER_OPTIONS"

export const usePeriodFilterOptions = () => {

  const request = useRequest()
  const { projectId } = useAppContext()

  const fetchPeriodFilterOptions = useCallback(async () => {
    const response = await request<{ start: string, end: string }[]>({
      url: "/v1/receipt/period-options?projectId=" + projectId
    })
    return response.body
  }, [ request, projectId ])

  return useQuery([ PERIOD_FILTER_OPTIONS_KEY, projectId ], fetchPeriodFilterOptions)

}

type ReceiptQueueItem = {
  accessKey: string;
  url: string;
  status: string;
}

type ReceiptQueueItemResult = { accessKeys: ReceiptQueueItem[], pageTotal: number }

export type FetchReceiptQueueParams = {
  page?: number;
  accessKeys?: string[];
  uf?: string;
  period?: string[];
}

export const RECEIPT_QUEUE_KEY = "RECEIPT_QUEUE"

export const useReceiptQueue = () => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState<any>(null)
  const [ data, setData ] = useState<null | ReceiptQueueItemResult>(null)
  const request = useRequest()
  const { projectId } = useAppContext()

  const fetch = useCallback(async (params: FetchReceiptQueueParams) => {
    const {
      page = 1, accessKeys, uf, period
    } = params

    const body: Record<string, any> = {
      projectId
    }
  
    if (page) body.page = page
    if (accessKeys) body.accessKeys = accessKeys
    if (uf) body.uf = uf
    if (period) body.period = period

    try {
      setIsLoading(true)
      setError(null)
      const response = await request<ReceiptQueueItemResult>({
        method: "POST", url: "/v1/receipt/search", body
      })
      const data = response.body
      setData(data)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }, [ request ])

  return { fetch, error, isLoading, data }
}

export const RECEIPTS_SENT_KEY = "RECEIPTS_SENT_KEY"

export const useReceiptsSent = (params: { start: string | null, end: string | null, uf: string | null }) => {

  const { start, end, uf } = params
  const { projectId } = useAppContext()
  const request = useRequest()

  const fetch = useCallback(async () => {
    const params: Record<string, string> = { projectId: String(projectId) }
    if (start) {
      params.start = start
    }
    if (end) {
      params.end = end
    }
    if (uf) {
      params.uf = uf
    }
    const response = await request<{ receiptsSent: number }>({
      url: `/v1/receipt/dashboard/receipts-sent?${new URLSearchParams(params)}`
    })
    return response.body
  }, [ request, start, end, uf ])

  return useQuery([ RECEIPTS_SENT_KEY, start, end, uf ], fetch)

}

export const RECEIPTS_PROCESSED_KEY = "RECEIPTS_PROCESSED_KEY"

export const useReceiptsProcessed = (params: { start: string | null, end: string | null, uf: string | null }) => {

  const { start, end, uf } = params
  const { projectId } = useAppContext()
  const request = useRequest()

  const fetch = useCallback(async () => {
    const params: Record<string, string> = { projectId: String(projectId) }
    if (start) {
      params.start = start
    }
    if (end) {
      params.end = end
    }
    if (uf) {
      params.uf = uf
    }
    const response = await request<{ receiptsProcessed: number }>({
      url: `/v1/receipt/dashboard/receipts-processed?${new URLSearchParams(params)}`
    })
    return response.body
  }, [ request, start, end, uf ])

  return useQuery([ RECEIPTS_PROCESSED_KEY, start, end, uf ], fetch)

}

export const RECEIPTS_INVALID_KEY = "RECEIPTS_INVALID_KEY"

export const useReceiptsInvalid = (params: { start: string | null, end: string | null, uf: string | null }) => {

  const { start, end, uf } = params
  const { projectId } = useAppContext()
  const request = useRequest()

  const fetch = useCallback(async () => {
    const params: Record<string, string> = { projectId: String(projectId) }
    if (start) {
      params.start = start
    }
    if (end) {
      params.end = end
    }
    if (uf) {
      params.uf = uf
    }
    const response = await request<{ receiptsInvalid: number }>({
      url: `/v1/receipt/dashboard/receipts-invalid?${new URLSearchParams(params)}`
    })
    return response.body
  }, [ request, start, end, uf ])

  return useQuery([ RECEIPTS_INVALID_KEY, start, end, uf ], fetch)

}