import { useCallback } from "react"
import useRequest from "./use-request"
import { useMutation } from "react-query"
import { useNavigate } from "react-router"
import { setAuthToken } from "../utils"

export const useLogout = () => {
  const request = useRequest()
  const navigate = useNavigate()

  const logout = useCallback(async () => {
    await request({
      method: "DELETE", url: "/v1/auth/session"
    })
    setAuthToken(null)
    navigate("/login")
  }, [ request, navigate ])

  return useMutation(logout)
}