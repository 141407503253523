import { ReactNode } from "react";
import styled from "styled-components"

type Props = {
  align?: "center" | "left" | "right";
  children?: ReactNode;
  gap?: string;
  padding?: string;
}

const Wrapper = styled.div<Props>`
  display: flex;
  justify-content: ${props => alignCss(props.align)};
  padding: ${props => props.padding || "0"};
  gap: ${props => props.gap || "0"};
`

const Row = (props: Props) => {
  return (
    <Wrapper {...props}>
      {props.children}
    </Wrapper>
  )
}

export default Row

function alignCss(align: string = "left"): string {
  if (align === "left") {
    return "flex-start"
  }
  if (align === "right") {
    return "flex-end"
  }
  if (align === "center") {
    return "center"
  }
  throw new Error()
}
