import Column from "../../ui/column"
import Title from "../../ui/title"
import Text from "../../ui/text"
import Layout from "../layout/layout"
import withRedirectUnauthenticated from "../../../hocs/with-redirect-unauthenticated"
import DashboardTab from "./dashboard-tab"
import Tabs from "../../ui/tabs"
import useUrlState from "../../../hooks/use-url-state"
import StatusSefazTab from "./status-sefaz-tab"

const HomePage = () => {
  const [ tab, setTab ] = useUrlState("tab", "home")
  return (
    <Layout>
      <Column padding="15px" gap="15px">
        <Column>
          <Title>
            Portal do Cliente
          </Title>
          <Text>
            Configuração e monitoramento
          </Text>
        </Column>
        <Tabs
          value={tab}
          options={[
            { id: "home", name: "Início" },
            { id: "sefaz", name: "Status Sefaz" }
          ]}
          onChange={setTab}
        />
        {tab === "home" ? <DashboardTab /> : null}
        {tab === "sefaz" ? <StatusSefazTab /> : null}
      </Column>
    </Layout>
  )
} 

export default withRedirectUnauthenticated(HomePage)