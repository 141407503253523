export type Model = {
  name: string;
  code: string;
}

export const Models: Model[] = [
  { name: "NFC-e", code: "65" },
  { name: "Danfe", code: "55" },
  { name: "SAT", code: "59" },
]

export const getModelByCode = (code: string): Model => {
  return Models.find(model => model.code === code)!
}