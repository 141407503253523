import { useCallback } from "react"
import { Project } from "../types"
import useRequest from "./use-request"
import { useQuery } from "react-query"

export const PROJECTS_KEY = "PROJECTS"

export const useProjects = () => {
  const request = useRequest()

  const fetchProjects = useCallback(() => request<{ result: Project[] }>({
    url: "/v1/project"
  }).then(response => response.body.result), [ request ])

  return useQuery(PROJECTS_KEY, fetchProjects)
}