
export type UF = {
  name: string;
  code: string;
  initials: string;
  models: string[];
}

export const UFs = [
  { code: '12', initials: 'AC', name: 'Acre', models: [ "65", "55" ] },
  { code: '27', initials: 'AL', name: 'Alagoas', models: [ "65", "55" ] },
  { code: '13', initials: 'AM', name: 'Amazonas', models: [ "65", "55" ] },
  { code: '16', initials: 'AP', name: 'Amapá', models: [ "65", "55" ] },
  { code: '29', initials: 'BA', name: 'Bahia', models: [ "65", "55" ] },
  { code: '23', initials: 'CE', name: 'Ceará', models: [ "65", "55", "59" ] },
  { code: '53', initials: 'DF', name: 'Distrito Federal', models: [ "65", "55" ] },
  { code: '32', initials: 'ES', name: 'Espirito Santo', models: [ "65", "55" ] },
  { code: '52', initials: 'GO', name: 'Goiás', models: [ "65", "55" ] },
  { code: '21', initials: 'MA', name: 'Maranhão', models: [ "65", "55" ] },
  { code: '51', initials: 'MT', name: 'Mato Grosso', models: [ "65", "55" ] },
  { code: '50', initials: 'MS', name: 'Mato Grosso do Sul', models: [ "65", "55" ] },
  { code: '31', initials: 'MG', name: 'Minas Gerais', models: [ "65", "55" ] },
  { code: '15', initials: 'PA', name: 'Pará', models: [ "65", "55" ] },
  { code: '25', initials: 'PB', name: 'Paraiba', models: [ "65", "55" ] },
  { code: '26', initials: 'PE', name: 'Pernanbuco', models: [ "65", "55" ] },
  { code: '22', initials: 'PI', name: 'Piauí', models: [ "65", "55" ] },
  { code: '41', initials: 'PR', name: 'Paraná', models: [ "65", "55" ] },
  { code: '33', initials: 'RJ', name: 'Rio de Janeiro', models: [ "65", "55" ] },
  { code: '24', initials: 'RN', name: 'Rio Grande do Norte', models: [ "65", "55" ] },
  { code: '43', initials: 'RS', name: 'Rio Grande do Sul', models: [ "65", "55" ] },
  { code: '11', initials: 'RO', name: 'Rondonia', models: [ "65", "55" ] },
  { code: '14', initials: 'RR', name: 'Roraima', models: [ "65", "55" ] },
  { code: '42', initials: 'SC', name: 'Santa Catarina', models: [ "65", "55" ] },
  { code: '35', initials: 'SP', name: 'São Paulo', models: [ "65", "55", "59" ] },
  { code: '28', initials: 'SE', name: 'Sergipe', models: [ "65", "55" ] },
  { code: '17', initials: 'TO', name: 'Tocantins', models: [ "65", "55" ] },
];

export const getUfByCode = (code: string): UF => {
  return UFs.find(uf => uf.code === code)!
}

export const getByInitials = (initials: string): UF => {
  return UFs.find(uf => uf.initials === initials.toUpperCase())!
}