import styled from "styled-components";

type Props = {
  title: string;
  value: string;
}

const Wrapper = styled.div`
  padding: 10px;
  border: solid 1px gray;
  border-radius: 5px;
`

const Title = styled.div`
  font-weight: bolder;
  font-size: 18px;
`

const Value = styled.div`
  font-size: 24px;
`

const BigNumberCard = (props: Props) => {
  const { title, value } = props
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Wrapper>
  )
}

export default BigNumberCard