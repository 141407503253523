import { RejectedHttpResponseError } from "../hooks/use-request"

export function getAuthToken(): string | null {
  return localStorage.getItem("SESSION_TOKEN")
}

export function setAuthToken(token: string | null) {
  if (!token) {
    localStorage.removeItem("SESSION_TOKEN")
    return
  }
  localStorage.setItem("SESSION_TOKEN", token)
}

export function formatSecondsTimestamp(timestamp: number | null | undefined): string {
  if (!timestamp) {
    return ""
  }
  const date = new Date(timestamp * 1000);
  const pad = (num: number): string => num.toString().padStart(2, '0');
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function getErrorMessage(error: any) {
  return error instanceof RejectedHttpResponseError ? error.message : "Um erro ocorreu"
}

export function isUrlValid(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}