import styled from "styled-components"

const Wrapper = styled.div`
  flex: 1;
`

const Gap = () => {
  return <Wrapper />
}

export default Gap