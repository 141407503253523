import styled from "styled-components"
import { ReactNode, useState } from "react"
import Column from "../../ui/column"
import { UF } from "../../../utils/uf"
import { getModelByCode, Model } from "../../../utils/model"
import { SefazUnavaiablePeriod } from "../../../hooks/reports"

const StatusBar = styled.div`
  position: relative;
`

const StatusBarItems = styled.div`
  display: flex;
  height: 20px;
  padding: 2px 0 5px 0;
  align-items: center;
`

const StatusBarItem = styled.div`
  background-color: green;
  flex: 1;
  height: 20px;
  cursor: pointer;
  border-right: 1px solid white;
  &:hover {
    opacity: 0.7;
  }
`

const StatusBarTitle = styled.div`
  font-size: 22px;
`

const Footer = styled.div`
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  padding: 0 8px 0 65px;
`

const FooterLegend = styled.div`
  font-size: 13px;
`

const ModelLegend = styled.div`
  width: 50px;
  padding-right: 5px;
  font-size: 14px;
  text-align: right;
`

const StatusBars = styled.div`
  border: solid 1px black;
  border-radius: 5px;
  padding: 8px;
  margin: 3px 0;
`

const ReportsDetailWrapper = styled.div`
  position: absolute;
  top: 100%;
  z-index: 100;
  width: 100%;
  transform: translateY(-5px);
  padding-top: 5px;
`

const ReportsDetail = styled.div`
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.75);
  padding: 10px;
`

const ReportsRetailTitle = styled.div`
  font-weight: bolder;
  text-align: center;
`

const NoReportsMessage = styled.div`
  padding: 20px;
  text-align: center;
  font-weight: normal;
`

function createDateList(startDt: Date, endDt: Date) {

  const dates: Date[] = []
  const actualDt = new Date(startDt)

  while (actualDt <= endDt) {
    dates.push(new Date(actualDt))
    actualDt.setDate(actualDt.getDate() + 1)
  }

  return dates

}

function formatDate(date: Date) {

  const today = new Date();

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return "HOJE"
  }

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${day}/${month}/${year}`

}

function findReports(
  params: {
    date: Date;
    uf: string;
    model: string;
    reports: SefazUnavaiablePeriod[];
  }
) {

  const {
    date, uf, model, reports
  } = params

  return reports.filter(report => {
    if (report.uf && report.uf !== uf) {
      return false
    }
    if (report.model && report.model !== model) {
      return false
    }
    if (report.endDt && report.endDt > date) {
      return false
    }
    if (report.startDt && report.startDt < date) {
      return false
    }
    return true
  })

}

const SefazStatusBar = (
  props: {
    uf: UF;
    startDt: Date;
    endDt: Date;
    reports: SefazUnavaiablePeriod[];
  }
) => {
  const {
    startDt, endDt, reports, uf
  } = props

  return (
    <Column>
      <StatusBarTitle>
        {uf.name}
      </StatusBarTitle>
      <StatusBars>
        {
          uf.models.map(code => (
            <SefazStatusModelBar
              key={code}
              model={getModelByCode(code)}
              startDt={startDt}
              endDt={endDt}
              reports={reports}
            />
          ))
        }
      </StatusBars>
      <Footer>
        <FooterLegend>
          {formatDate(startDt)}
        </FooterLegend>
        <FooterLegend>
          {formatDate(endDt)}
        </FooterLegend>
      </Footer>
    </Column>
  )
}

const SefazStatusModelBar = (
  props: {
    model: Model;
    startDt: Date;
    endDt: Date;
    reports: SefazUnavaiablePeriod[];
  }
) => {
  const {
    startDt, endDt, model
  } = props

  const [ currentDate, setCurrentDate ] = useState<Date | null>(null)

  const items: ReactNode[] = []

  for (const date of createDateList(startDt, endDt)) {
    items.push(
      <StatusBarItem
        onMouseEnter={() => setCurrentDate(date)}
        onMouseLeave={() => setCurrentDate(null)}
      />
    )
  }

  return (
    <StatusBar>
      <StatusBarItems>
        <ModelLegend>
          {model.name}
        </ModelLegend>
        {items}
      </StatusBarItems>
      {
        !currentDate ? null : (
          <ReportsDetailWrapper>
            <ReportsDetail>
              <ReportsRetailTitle>
                {formatDate(currentDate)}
                <NoReportsMessage>
                  Nenhuma instabilidade detectada
                </NoReportsMessage>
              </ReportsRetailTitle>
            </ReportsDetail>
          </ReportsDetailWrapper>
        )
      }
    </StatusBar>
  )

}

export default SefazStatusBar