import { ReactNode } from "react"
import styled from "styled-components"

const Wrapper = styled.p`
  padding: 0;
  margin: 0;
`

const Text = (props: { children?: ReactNode }) => {
  return (
    <Wrapper>
      {props.children}
    </Wrapper>
  )
}

export default Text