import { createGlobalStyle } from "styled-components";

const LoadingStyle = createGlobalStyle`
  .lds-facebook,
  .lds-facebook div {
    box-sizing: border-box;
  }
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 12px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    width: 3px;
    background: currentColor;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 0px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 6px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 12px;
    animation-delay: 0s;
  }
  @keyframes lds-facebook {
    0% {
      top: 3px;
      height: 6px;
    }
    50%, 100% {
      top: 0px;
      height: 12px;
    }
  }
`

const LoadingAnimatedIcon = () => {
  return (
    <>
      <LoadingStyle />
      <div className="lds-facebook"><div></div><div></div><div></div></div>
    </>
  )
}

export default LoadingAnimatedIcon