import styled from "styled-components"

type Props = {
  invertColors?: boolean;
  options: Array<{ id: string; name: string }>;
  onChange?: (id: string) => void;
  value?: string
}

const Wrapper = styled.div<{ invertColors: boolean }>`
  display: flex;
  gap: 20px;
  position: relative;
  border-bottom: solid 1px ${props => props.invertColors ? "white" : "black"};
  color: ${props => props.invertColors ? "white" : "black"};
`

const Tab = styled.div<{ selected: boolean, invertColors: boolean }>`
  border-bottom: solid 2px ${props => props.selected ? (props.invertColors ? "white !important" : "black !important") : "transparent"};
  font-weight: ${props => props.selected ? "bolder" : "normal"};
  padding: 15px 0;
  cursor: pointer;
  user-select: none;
  &:hover {
    border-bottom: solid 2px lightgray;
  }
`

const Tabs = (props: Props) => {
  const {
    invertColors = false, 
    options = [], 
    onChange, 
    value
  } = props
  return (
    <Wrapper invertColors={invertColors}>
      {
        options.map(option => (
          <Tab
            id={option.id}
            selected={option.id === value}
            invertColors={invertColors}
            onClick={() => onChange?.(option.id)}>
            {option.name}
          </Tab>
        ))
      }
    </Wrapper>
  )
}

export default Tabs