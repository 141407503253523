import { useCallback } from "react"
import { Webhook } from "../types"
import useRequest, { RejectedHttpResponseError } from "./use-request"
import { useMutation, useQuery, useQueryClient } from "react-query"
import useAppContext from "../context/use-app-context"

export const WEBHOOK_KEY = "WEBHOOK_KEY"

export const useWebhookConfig = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const fetchWebhookConfig = useCallback(async () => {
    try {
      const response = await request<Webhook>({
        url: "/v1/webhook?projectId=" + projectId
      })
      return response.body
    } catch (e) {
      if (e instanceof RejectedHttpResponseError) {
        if (e.body.errorCode === "WEBHOOK_NOT_CONFIGURED_ERROR") {
          return null
        }
      }
      throw e
    }
  }, [ request, projectId ])

  return useQuery([ WEBHOOK_KEY, projectId ], fetchWebhookConfig)
}

export const useSaveWebhookConfig = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const saveWebhookConfig = useCallback((params: { url: string | null, authToken?: string | null }) => request({
    method: "POST",
    url: "/v1/webhook",
    body: {
      ...params, projectId
    }
  }).then(response => response.body), [ request, projectId ])

  return useMutation(saveWebhookConfig)
}

export const useDisableWebhook = () => {
  const request = useRequest()
  const { projectId } = useAppContext()

  const saveWebhookConfig = useCallback(() => request({
    method: "DELETE",
    url: "/v1/webhook?projectId=" + projectId
  }).then(response => response.body), [ request, projectId ])

  return useMutation(saveWebhookConfig)
}

export const useInvalidateWebhook = () => {
  const queryClient = useQueryClient()
  const { projectId } = useAppContext()

  return useCallback(() => {
    queryClient.invalidateQueries([ WEBHOOK_KEY, projectId ], {
      refetchActive: true
    })
  }, [ queryClient, projectId ])
}