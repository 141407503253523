import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Modal from "../../ui/modal"
import { ApiToken } from "../../../types"
import Column from "../../ui/column"
import Row from "../../ui/row"
import Button from "../../ui/button"
import { useDeleteApiToken, useInvalidateApiTokens, useRenameApiToken } from "../../../hooks/api-tokens"
import { getErrorMessage } from "../../../utils"
import TextField from "../../ui/text-field"

const Wrapper = styled.div`
  position: relative;
`

const Content = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

const Title = styled.div`
  font-weight: bolder;
  font-size: 14px;
  color: blue;
`

const ExpandIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid blue;
`

const Options = styled.div`
  z-index: 800;
  position: absolute;
  top: 120%;
  right: 0;
  border: solid 1px black;
  min-width: 150px;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  text-align: left;
`

const Option = styled.div`
  padding: 10px;
  &:hover {
    background-color: lightgray;
    cursor: pointer;
    user-select: none;
  }
`

const TokenOptionsButton = (props: { token: ApiToken }) => {
  const {
    token
  } = props
  const [ open, setOpen ] = useState(false)
  const [ error, setError ] = useState<any>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  const [ confirmDeleteOpen, setConfirmDeleteOpen ] = useState(false)
  const [ renameOpen, setRenameOpen ] = useState(false)
  const [ newLabel, setNewLabel ] = useState<string | null>(null)
  const invalidateApiTokens = useInvalidateApiTokens()
  const {
    mutateAsync: deleteApiToken,
    isLoading: isDeletingApiToken
  } = useDeleteApiToken()
  const {
    mutateAsync: renameApiToken,
    isLoading: isRenamingApiToken
  } = useRenameApiToken()

  const onConfirmDelete = async () => {
    try {
      await deleteApiToken({ label: token.label })
      invalidateApiTokens()
    } catch (e)  {
      setError(e)
    } finally {
      setConfirmDeleteOpen(false)
    }
  }

  const onConfirmRename = async () => {
    try {
      await renameApiToken({ label: token.label, newLabel: newLabel! })
      invalidateApiTokens()
    } catch (e)  {
      setError(e)
    } finally {
      setRenameOpen(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as any)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ setOpen, optionsRef ])

  const onNewLabelChange = (value: string | null) => {
    if (!value) {
      setNewLabel(value)
      return
    }
    setNewLabel(value.toUpperCase().replace(/[^0-9A-Z]/g, "_"))
  }

  return (
    <Wrapper>
      <Modal open={error !== null} onClose={() => setError(null)}>
        {error && getErrorMessage(error)}
      </Modal>
      <Modal open={confirmDeleteOpen} type="BLANK">
        <Column gap="20px">
          <div>
            Confirma deletar o token <strong>{props.token.label}</strong>?<br />
            <br />
            Todos os sistemas que ainda estão utilizando este token irão parar de funcionar.
          </div>
          <Row align="center" gap="15px">
            <Button onClick={onConfirmDelete} layout="danger" loading={isDeletingApiToken}>
              Ok
            </Button>
            <Button onClick={() => setConfirmDeleteOpen(false)} layout="secundary">
              Cancelar
            </Button>
          </Row>
        </Column>
      </Modal>
      <Modal open={renameOpen} type="BLANK">
        <Column gap="20px">
          <div>
            Mudar o identificador de um token não causa nenhum efeito em produção, pois estes identificadores
            servem apenas para organização.<br />
            <br />
            Use um nome que te ajude a identificar o uso deste token!
          </div>
          <TextField
            label={`Novo nome para o token ${props.token.label}`}
            value={newLabel}
            onChange={onNewLabelChange}
          />
          <Row align="center" gap="15px">
            <Button onClick={onConfirmRename} loading={isRenamingApiToken} disabled={!newLabel}>
              Renomear
            </Button>
            <Button onClick={() => setRenameOpen(false)} layout="secundary">
              Cancelar
            </Button>
          </Row>
        </Column>
      </Modal>
      <Content onClick={() => setOpen(true)}>
        <Title>
          Opções
        </Title>
        <ExpandIcon />
      </Content>
      {
        !open ? null : (
          <Options ref={optionsRef}>
            <Option onClick={() => setConfirmDeleteOpen(true)}>
              Apagar Token
            </Option>
            <Option onClick={() => {
              setRenameOpen(true)
              setNewLabel(token.label)
            }}>
              Renomear Token
            </Option>
          </Options>
        )
      }
    </Wrapper>
  )
}

export default TokenOptionsButton